import Vue from 'vue'
import { api } from '@/config'
const { apiRoot } = api

const actions = {
  loadCompany (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/company/get', {
          params: { id: companyId }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  retrieveAllCompanies (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/company/list-all')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCompanyVersionTypes (context, companyId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/companies/' + companyId + '/campaign-version-types')
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  }

}
export default actions
