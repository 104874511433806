import Vue from 'vue'
import contactMethods from './contactMethods'
import comments from './comments'

function updateStoreList (response) {
  Vue.$store.state.Contact.list = response
  return response
}

function updateStoreContact (id) {
  return function (response) {
    const contacts = [...Vue.$store.state.Contact.list.data]
    const idx = contacts.findIndex((contact) => contact.id === id)
    contacts[idx] = response
    Vue.$store.state.Contact.list.data = contacts
    return response
  }
}

function create (data = {}) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api.post('/contacts', { params, data })
}

function list (params = {}) {
  return Vue.api.get('/contacts', {
    params: {
      companyId: Vue.$store.state.Workspace.filters.companyId,
      companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId,
      ...params
    }
  })
    .then(updateStoreList)
}

function load (id) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api
    .get(`/contacts/${id}`, { params })
    .then(updateStoreContact(id))
}

function update (id, data = {}) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api
    .patch(`/contacts/${id}`, { params, data })
    .then(updateStoreContact(id))
}

function _delete (id) {
  const params = {
    companyId: Vue.$store.state.Workspace.filters.companyId,
    companyGroupId: Vue.$store.state.Workspace.filters.companyGroupId
  }
  return Vue.api.delete(`/contacts/${id}`, { params })
}

export default {
  create,
  list,
  load,
  update,
  delete: _delete,
  contactMethods,
  comments
}
