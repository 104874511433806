<template>
  <div :class="taskContainerBorderClasses">
    <div
      class="d-flex flex-row"
      :class="isExpanded ? 'expandable-box-show' : 'expandable-box'"
    >
      <div>
        <input
          v-if="task.isCompleted"
          type="checkbox"
          checked="true"
          class="border h-4 w-4 text-blue-500 border-gray-300 rounded checkbox-completed"
        >
        <input
          v-else
          type="checkbox"
          class="border h-4 w-4 text-blue-500 border-gray-300 rounded checkbox"
          @click="$emit('click', task.id)"
        >
      </div>
      <div
        ref="wrapper"
        class="text-wrapper"
      >
        <div
          v-if="task.isCompleted"
          ref="content"
          class="content strike-through"
          v-html="formattedTaskDetails"
        />
        <div
          v-else
          ref="content"
          class="content"
          v-html="formattedTaskDetails"
        />
      </div>

      <table-action-menu
        :menu-items="taskMenuItems"
        class="edit-menu"
      />
      <div
        v-if="showExpandButton"
        class="expand-button"
        @click="toggleExpansion"
      >
        <task-condense-icon
          v-if="isExpanded"
          w="28"
          h="24"
        />
        <task-expand-icon
          v-else
          w="28"
          h="24"
        />
      </div>
    </div>

    <div
      v-if="taskable"
      class="link-to-comment"
      @click="navToCommentOnTaskable"
    >
      View Comment
    </div>

    <div class="footer d-flex flex-row align-items-center justify-space-between">
      <div
        v-if="activeTab==='myTasks'"
        class="d-flex flex-row align-items-center my-tasks-footer"
      >
        <div class="avatar">
          <circle-image
            v-if="task.createdByUser.avatarUrl"
            class="avatar-image"
            :image-url="task.createdByUser.avatarUrl"
            :size="24"
          />
          <div
            v-else
            class="avatar-initial"
          >
            {{ task.createdByUser.initials }}
          </div>
        </div>
        <div class="name">
          {{ task.createdByUser.fullName }}
        </div>
      </div>
      <div
        v-if="activeTab==='assigned'"
        class="d-flex flex-row align-items-center"
      >
        <div class="task-assigned">
          <task-assigned-icon
            w="16"
            h="16"
          />
        </div>

        <div
          v-for="user in task.assignedUsers"
          :key="user.id"
          class="d-flex flex-row align-items-center justify-space-between assignee-tooltip"
        >
          <avatar-tooltip
            :key="user.id"
            position="top"
          >
            <template #tip>
              <div class="tool-tip">
                <div class="tool-tip-header">
                  Assignee
                </div>
                <div class="tool-tip-body">
                  <div class="d-flex flex-row align-items-center justify-content-between gap-x-3">
                    <div class="avatar">
                      <circle-image
                        v-if="user.avatarUrl"
                        class="avatar-image"
                        :image-url="user.avatarUrl"
                        :size="24"
                      />
                      <div
                        v-else
                        class="avatar-initial"
                      >
                        {{ user.initials }}
                      </div>
                    </div>
                    <div class="tool-tip-contact">
                      <div class="info-text">
                        {{ user.fullName }}
                      </div>
                      <div class="d-flex flex-row align-items-center gap-x-1">
                        <div
                          v-if="user.title"
                          class="role-pill"
                        >
                          {{ user.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #hover-item>
              <div class="avatar">
                <circle-image
                  v-if="user.avatarUrl"
                  class="avatar-image"
                  :image-url="user.avatarUrl"
                  :size="24"
                />
                <div
                  v-else
                  class="avatar-initial"
                >
                  {{ user.initials }}
                </div>
              </div>
            </template>
          </avatar-tooltip>

          <div
            v-if="task.assignedUsers?.length===1"
            class="name"
          >
            {{ task.assignedUsers[0].fullName }}
          </div>
        </div>
      </div>
      <div
        v-if="activeTab==='completed'"
        class="d-flex flex-row align-items-center my-tasks-footer"
      >
        <div class="avatar">
          <circle-image
            v-if="task.completedByUser.avatarUrl"
            class="avatar-image"
            :image-url="task.completedByUser.avatarUrl"
            :size="24"
          />
          <div
            v-else
            class="avatar-initial"
          >
            {{ task.completedByUser.initials }}
          </div>
        </div>
        <div class="name">
          {{ task.completedByUser.fullName }}
        </div>
      </div>
      <div
        v-if="task.dueDate && task.isActive"
        class="due-date d-flex flex-row align-items-center"
      >
        <div
          v-if="task.isPastDue || task.numberOfRemainingDays === 0 || task.isDueSoon"
          class="due-date-alert"
        >
          <warning-icon
            w="20"
            h="21"
            :class="{ 'warning-icon-error': (task.isPastDue || task.numberOfRemainingDays === 0), 'warning-icon': task.isDueSoon }"
          />
        </div>
        <div>
          {{ dueMessage() }}
        </div>
      </div>
      <div
        v-if="task.isCompleted"
        class="due-date"
      >
        Completed: {{ getDate(task.completedAt) }}
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { TaskExpandIcon, TaskCondenseIcon, TaskAssignedIcon, WarningIcon } from '@/components/icons'
import CommentTaskNavigtion from '@/mixins/CommentTaskNavigation'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  components: {
    TaskExpandIcon, TaskCondenseIcon, TaskAssignedIcon, WarningIcon
  },
  mixins: [
    CommentTaskNavigtion
  ],
  props: {
    task: {
      type: Object,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    }

  },
  data () {
    return {
      isExpanded: false,
      showExpandButton: false,
      isSelected: false,
      taskMenuItems: [
        {
          text: 'Edit',
          icon: 'edit',
          click: () => { this.$emit('edit', this.task) }
        },
        {
          text: 'Delete',
          icon: 'delete',
          click: () => { this.$emit('delete', this.task.id) }
        }
      ]
    }
  },
  computed: {

    shouldHighlight () {
      return this.task.id === parseInt(this.$route.query?.taskId)
    },

    taskable () {
      return this.task.taskable
    },

    formattedTaskDetails () {
      return this.linkify(this.task.details)
    },

    taskContainerBorderClasses () {
      const classes = []

      if (this.isExpanded) {
        classes.push('task-container-show')
      } else {
        classes.push('task-container')
      }

      if (this.task.isCompleted) {
        classes.push('border-left-completed')
      } else if (this.task.isPastDue || this.task.numberOfRemainingDays === 0) {
        classes.push('border-left-past-due')
      } else if (this.task.isDueSoon) {
        classes.push('border-left-due-soon')
      } else {
        classes.push('border-left-pending')
      }

      if (this.shouldHighlight) {
        classes.push('focused-task')
      }

      return classes
    }
  },

  mounted () {
    this.checkOverflow()
    window.addEventListener('resize', this.checkOverflow)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.checkOverflow)
  },

  methods: {

    navToCommentOnTaskable () {
      if (!this.taskable) return

      // eventually we'll have more taskable types 🤞
      if (this.taskable.commentable) {
        const commentable = this.taskable.commentable
        commentable.commentId = this.taskable.id
        this.$emit('close')

        // methods in the mixin
        if (this.task.taskable.commentableType === 'mediaAsset') {
          this.goToMediaAssetComment(commentable)
        } else if (this.task.taskable.commentableType === 'campaign') {
          commentable.campaignId = commentable.id
          this.goToCampaignComment(commentable)
        } else if (this.task.taskable.commentableType === 'campaignFile') {
          commentable.campaignId = commentable.campaign.id
          this.goToCampaignFileComment(commentable)
        } else if (this.task.taskable.commentableType === 'contact') {
          this.goToContactComment(commentable)
        }
      }
    },

    dueMessage () {
      if (this.task.isPastDue) return 'Past Due'
      if (this.task.numberOfRemainingDays === 0) return 'Due Today'
      if (this.task.numberOfRemainingDays === 1) return 'Due Tomorrow'
      if (this.task.isDueSoon) return `Due in ${this.task.numberOfRemainingDays} days`
      return 'Due: ' + this.getDate(this.task.dueDate)
    },

    checkOverflow () {
      const wrapperWidth = this.$refs.wrapper.offsetWidth
      const contentWidth = this.$refs.content.offsetWidth

      this.showExpandButton = !(wrapperWidth > contentWidth)
    },

    getDate (timestamp) {
      return dayjs.tz(timestamp, 'America/Toronto').format('MM/D')
    },

    toggleExpansion () {
      this.isExpanded = !this.isExpanded
    },

    linkify (message) {
      if (!message) return

      const urlRegex = /(<img\s[^>]*>|<a(?:\s[^>]*)?>[\s\S]*?<\/a>)|(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gi
      return message.replace(urlRegex, function (url, tag) {
        if (tag) return url

        let hyperlink = url
        if (!hyperlink.match('^https?://')) {
          hyperlink = `http://${hyperlink}`
        }
        return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`
      })
    }
  }
}
</script>

<style scoped>
.task-container {
  border: 1px solid rgba(221, 221, 221, 1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  margin-left: 1em;
  width: 100%;
  max-width: 33em;
  margin-right: 1em;
}
.task-container-show {
  border: 1px solid rgba(46, 118, 188, 1) ;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
  background: rgba(248, 250, 253, 1);
  width: 100%;
  max-width: 33em;
}
@media (max-width: 3000px) {
  .task-container {
    max-width: 50em;
  }

  .task-container-show  {
    max-width: 50em;

  }
}
@media (max-width: 2700px) {
  .task-container {
    max-width: 46em;
  }

  .task-container-show  {
    max-width: 46em;

  }
}

@media (max-width: 2500px) {
  .task-container {
    max-width: 43em;
  }

  .task-container-show  {
    max-width: 43em;
  }
}

@media (max-width: 2000px) {
  .task-container {
    max-width: 34em;
  }

  .task-container-show  {
    max-width: 34em;
  }
}

@media (max-width: 1900px) {
  .task-container {
    max-width: 33em;
  }

  .task-container-show  {
    max-width: 33em;
  }
}

@media (max-width: 1800px) {
  .task-container {
    max-width: 30em;
  }

  .task-container-show  {
    max-width: 30em;
  }
}

@media (max-width: 1680px) {
  .task-container {
    max-width: 29em;
  }

  .task-container-show  {
    max-width: 29em;
  }
}

@media (max-width: 1600px) {
  .task-container {
    max-width: 25em;
  }

  .task-container-show  {
    max-width: 25em;
  }
}

@media (max-width: 1450px) {
  .task-container {
    max-width: 24em;
  }

  .task-container-show  {
    max-width: 24em;
  }
}

@media (max-width: 1350px) {
  .task-container {
    max-width: 23em;
  }

  .task-container-show  {
    max-width: 23em;
  }
}

@media (max-width: 1300px) {
  .task-container {
    max-width: 22em;
  }

  .task-container-show  {
    max-width: 22em;
  }
}

@media (max-width: 1250px) {
  .task-container {
    max-width: 21em;
  }

  .task-container-show  {
    max-width: 21em;
  }
}

@media (max-width: 1200px) {
  .task-container {
    max-width: 20em;
  }

  .task-container-show  {
    max-width: 20em;
  }
}

@media (max-width: 1050px) {
  .task-container {
    max-width: 16em;
  }

  .task-container-show  {
    max-width: 16em;
  }
}

.border-left-past-due {
  border-left: 8px solid rgba(215, 59, 67, 1) ;
}

.border-left-due-soon{
  border-left: 8px solid rgba(225, 153, 48, 1) ;
}

.border-left-completed{
  border-left: 8px solid rgba(67, 160, 71, 1) ;
}

.border-left-pending{
  border-left: 8px solid rgba(46, 118, 188, 1) ;
}

.header, .footer {
  padding: 10px;
  align-items: center;
  height: 4em;
}

.header {
  display:flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.footer {
  border-top: 1px solid #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(79, 108, 136, 1);
  overflow: hidden;
}

.expandable-box {
  height: 44px;
  padding: 10px 10px 10px 10px;
  justify-content: space-between;
}

.expandable-box-show {
  height: auto;
  padding: 10px 10px 10px 10px;
  justify-content: space-between;
}

.text-blue-500 {
  color: var(--link-color);
}

.checkbox {
  accent-color: var(--checkbox-checked-background-color);
}

.checkbox-completed {
  pointer-events: none; /* Prevents the element from receiving pointer events */
  cursor: not-allowed;
  accent-color: var(--checkbox-checked-background-color);
}

.text-wrapper {
  position: relative;
  width: 90%;
  overflow:hidden;
  text-overflow: ellipsis;
  left: 5px;
}

.assignee-row {
  display: flex;
  height: 45px;
  align-items: center;
}

.left-column {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 35px;
  padding-left: 0.3rem;
}

.avatar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 24px;
  height: 24px;
  right: 5px;
  border-radius: 50%;
  background-color: rgba(217, 217, 217, 1);
  background-size: cover;
  background-position: 50% 50%;
}

.avatar-initial {
  font-size: 12px;
  line-height: 23px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  z-index: 1;
  text-transform: uppercase;
}

.center-column {
  flex-grow: 2;
  width: 14em;
}

.name {
  font-weight: 500;
  display: flex;
  line-height: 1.3em;
}

.list-row-more-icon {
  display: inline-block;
  transform: rotate(90deg);
  margin-left: 10px;
  cursor: pointer;
  color: rgba(46, 118, 188, 1);
}

.content {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.avatar-image {
  position: absolute;
  top: 0px;
  z-index: 2;
}

.blue-dot {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: -0.7em;
  background-color: #2e76bc;
}

.due-date{
  font-family: Poppins;
  font-size: 11px;
  font-style: italic;
  overflow: hidden;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: auto;
  padding-right: 2px;
}

.strike-through {
  text-decoration: line-through;
}

.due-date-alert {
  margin-right: 0.5em;
  margin-top: 0.5em;
}

.edit-menu:deep(.action-menu){
  right: 30px;
  min-width: 120px;
}

.edit-menu:deep(.action-menu-link){
  margin: -5px 0 0 0 ;
}

.tool-tip {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dddddd;
}

.tool-tip-header {
  background-color: #2a6cab;
  color: white;
  padding: 13px;
}

.tool-tip-body {
  background-color: white;
  color: black;
  padding: 13px;
}

.gap-x-3 {
  column-gap: 0.75rem;
}

.tool-tip-contact {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.gap-x-1 {
  column-gap: 0.25rem;
}

.role-pill {
  padding: 4px 8px;
  border-radius: 0.375rem;
  background-color: var(--edit-link-background-color);
  color: var(--text-accent-color);
  font-size: 11px;
}

.warning-icon {
  fill: var(--text-warning-color);
}

.warning-icon-error{
  fill: var(--text-error-color);
}
.task-assigned{
  margin-right: 5px;
}
.assignee-tooltip{
  padding-left: 0.3rem;
}
.my-tasks-footer{
  padding-left:0.3rem;
}

.link-to-comment{
  padding: 5px 40px;
  font-size: .8em;
  text-decoration: underline;
  cursor: pointer;
  color: #2e76bc;
}

.focused-task{
  background-color: rgb(241, 241, 241);
}
</style>
